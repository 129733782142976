<template>
  <div class="subtask-container">
    <div style="display: flex; align-items: center">
      <div @click="emit('changeStatus', subtask, index, column)">
        <input
          :class="subtask.status === 'in-review' ? 'checked' : 'unchecked'"
          ref="checkbox"
          :disabled="subtask.status === 'completed'"
          type="checkbox"
          :checked="
            subtask.status === 'completed' || subtask.status === 'in-review'
          "
        />
      </div>
      <h4 :class="getText(subtask.status)">{{ subtask.name }}</h4>
      <v-tooltip v-if="subtask.tooltip">
        <img :src="info" style="margin-left: 5px" alt="info" />
        <template #popper>
          <span class="tooltip-text">{{ subtask.tooltip }}</span>
        </template>
      </v-tooltip>
    </div>
    <div v-if="subtask.link" class="link-area">
      <img
        @click="openExternal()"
        :src="
          subtask.status === 'completed' ||
          subtask.status === 'in-review' ||
          subtask.status === 'complete'
            ? externalLinkDisabled
            : externalLink
        "
        alt="external link icon"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import externalLink from "@/assets/icons/external-link.svg";
import externalLinkDisabled from "@/assets/icons/external-link-disabled.png";
import info from "@/assets/icons/info.svg";

const emit = defineEmits(["click", "changeStatus"]);

const getText = (status) => {
  if (status === "completed" || status === "in-review") return "finished-task";
  else return "unfinished-task";
};

const props = defineProps({
  subtask: {
    type: Object,
    required: true,
  },
  index: {
    required: true,
  },
  column: {
    required: true,
  },
});
const openExternal = () => {
  "click:", props.subtask.link;
  window.open(props.subtask.link, "_blank");
};
const click = () => {
  window.open(props.subtask.link, "_blank");
};
</script>

<style scoped>
.finished-task {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-decoration-line: line-through;

  /* Strikeout */

  color: #908f8f;
}
.unfinished-task {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
}
input[type="checkbox"] {
  margin-right: 13px;
  width: 21px;
  height: 21px;
  /* HD Orange */
  color: #f96302;
  border: 1px solid #c1c1c1;
  border-radius: 3px;
}
input[type="checkbox"]:checked {
  accent-color: #f96302;
  pointer-events: none;
}
.subtask-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  border-radius: 10px;
  background: #f4f6f5;
  cursor: pointer;
  height: 77px;
}

h4 {
  margin: 0;
  font-size: 16px;
}
.tooltip-text {
  font-family: "Helvetica Neue Lt Std", Arial, sans-serif;
}
</style>
<style>
.v-popper--theme-tooltip .v-popper__wrapper {
  margin-bottom: 5px;
}
.link-area {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 77px;
  height: 77px;
  background: rgba(219, 222, 222, 0.46);
  border-radius: 0px 10px 10px 0px;
}
.v-popper--theme-tooltip .v-popper__inner {
  max-width: 200px;
  padding: 15px;
  background: #4a494f;
  text-align: center;
  font-size: 12px;
}
</style>
